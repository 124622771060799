<template>
    <div class="live-room-manage">
        <div class="live-title">直播间管理</div>
        <el-table class="table-live-student" v-if="liveList.length != 0" :data="liveList">
            <el-table-column prop="nickname" label="主播信息">
                <template slot-scope="scope">
                    <div class="info-box">
                        <img
                            class="info-circle"
                            :src="scope.row.avatar ? scope.row.avatar : require('../../../assets/images/blank_headPic.png')"
                            alt=""
                        />
                        <div class="info-name">{{ scope.row.nickname }}</div>
                    </div>
                </template>
            </el-table-column>
            <el-table-column prop="title" label="直播间信息" align="center">
                <template slot-scope="scope">
                    <div class="info-box">
                        <img
                            class="info-square"
                            :src="scope.row.cover ? scope.row.cover : require('../../../assets/images/live_manage.png')"
                            alt=""
                        />
                        <div class="info-name" style="width: 300px">{{ scope.row.title }}</div>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="操作" align="center">
                <template slot-scope="scope">
                    <div class="op-content">
                        <el-button class="op-btn" @click="toDegger">直播调试</el-button>
                        <div class="op-btn" @click="startLive(scope.row)">开播</div>
                        <div class="op-btn" @click="editBtn(scope.row)">编辑</div>
                        <div class="op-btn danger" @click="delBtn(scope.row)">删除</div>
                    </div>
                </template>
            </el-table-column>
        </el-table>
        <div class="empty-data" v-if="liveList.length == 0">
            <img class="no-data" src="../../../assets/images/no_data_coupon.png" alt="" />
            <div class="no-data-tips">暂无直播间</div>
            <el-button type="primary" @click="createLive">创建直播间</el-button>
        </div>
<!--        <el-dialog title="小程序推流入口" :visible.sync="dialogVisible" width="500px" top="26vh" :before-close="handleClose">-->
<!--            <div class="dialog-content">-->
<!--                <span class="dialog-title">主播通过微信扫码进入直播小程序进行推流</span>-->
<!--                <div class="qr-content">-->
<!--                    <div id="qrCode" ref="qrCodeDiv" class="qrCodeDiv"></div>-->
<!--                </div>-->
<!--                <div class="bottom-content">-->
<!--                    <el-button type="primary" @click="goToLive">进入电脑端</el-button>-->
<!--                </div>-->
<!--            </div>-->
<!--        </el-dialog>-->
    </div>
</template>

<script>
import {getLiveSaleStudentCheck, postDeleteRoom, postRoomList} from "@/utils/apis";
import router from "@/router";

export default {
    name: "RoomsManagement",
    data() {
        return {
            liveList: []
        }
    },
    created() {
        // if (localStorage.getItem('liveBaseInfo')) {
        //     this.liveList.push(JSON.parse(localStorage.getItem('liveBaseInfo')))
        // } else {
        //     this.liveList = []
        // }
        this.postRoomListFn()
    },
    methods: {
        toDegger() {
            router.push({
                path: '/student/liveTest'
            })
        },
        startLive(val) {
            // getLiveSaleStudentCheck().then(res => {
            //     if (res.code == 200) {
                    this.$router.push({
                        path: '/student/liveRoom',
                        query: {
                            id:val.id
                        }
                    })
                // } else {
                //     this.$message.error(res.msg)
                // }
            // })
        },
        editBtn(val) {
            this.$router.push({
                path: "/liveRoomManage/createLive",
                query: {
                    id: val.id
                }
            });
        },
        delBtn(val) {
            this.$confirm("删除后将无法恢复，确定要删除？", "提示消息", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
                customClass: "msgBoxClass",
            }).then(() => {
                localStorage.removeItem('liveBaseInfo')
                localStorage.removeItem('baseInfo')
                let params = {
                    id: val.id
                }
                postDeleteRoom(params).then(res => {
                    if (res.code == 200) {
                        this.postRoomListFn()
                        this.$message.success(res.msg)
                    }
                })
            }).catch(() => {
                this.$message.info("已取消删除");
            });
        },
        createLive() {
            this.$router.push("/liveRoomManage/createLive");
        },
        postRoomListFn() {
            postRoomList().then(res => {
                if (res.code == 200) {
                    this.liveList = res.data.list
                }
            })
        }
    }
}
</script>

<style scoped lang="scss">
.live-room-manage {
    padding: 20px;
    height: calc(100% - 40px);
    display: flex;
    flex-direction: column;
    background: #fff;

    .live-title {
        font-size: 16px;
        font-weight: 500;
        color: #ffffff;
        //margin-bottom: 80px;
    }
}

.no-data-table {
    height: 60px !important;
}

/deep/.el-table.table-live-student {
    color: #455A94;
    font-weight: 500;
    th {
        color: #8CA5C6;
        background-color: #F8F9FB!important;
    }
    .el-table__row {
        height: 60px;
    }
    .el-link {
        margin-right: 24px;
        &:last-child {
            margin-right: 0;
        }
    }
}

.info-box {
    display: flex;
    align-items: center;

    .info-circle {
        width: 54px;
        height: 54px;
        border-radius: 50%;
    }

    .info-square {
        width: 100px;
        height: 100px;
        margin-right: 30px;
    }

    .info-name {
        width: 200px;
        margin-left: 10px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-align: left;
    }
}

.op-content {
    display: flex;
    justify-content: center;

    .op-btn + .op-btn {
        margin-left: 20px;
    }

    .op-btn {
        width: 76px;
        height: 30px;
        background: rgba(75, 69, 255, 0.1);
        border: 1px solid #4b45ff;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #4b45ff;
        cursor: pointer;

        &.danger {
            border: 1px solid #ff3e6c;
            background: rgba(255, 62, 108, 0.1);
            color: #ff3e6c;
        }
    }
}

.empty-data {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 117px;

    .no-data {
        width: 388px;
        height: 326px;
    }

    .no-data-tips {
        margin: 58px 0 34px;
        color: #fff;
    }

    //.el-button {
    //    background: #4b45ff;
    //}
}

::v-deep .el-dialog {
    background: #070932;

    .el-dialog__title {
        font-size: 16px;
        font-weight: bold;
        color: #fff;
        position: relative;
        margin-left: 20px;

        &:before {
            position: absolute;
            content: "";
            width: 4px;
            height: 12px;
            background: #4b45ff;
            left: -15px;
            top: 2px;
        }
    }
}

.dialog-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .dialog-title {
        font-size: 16px;
        color: #fff;
        margin-top: 18px;
    }

    .qr-content {
        width: 222px;
        height: 222px;
        margin: 30px 0 44px;

        .qrCodeDiv {
            height: 100%;
            width: 100%;
        }
    }
}
</style>
